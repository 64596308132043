@import "~antd/dist/antd.less";
@import "./var.less";
@import "./content.less";

@import "./utils/media.less";
@import "./utils/space.less";

.global-layout {
  min-height: 100%;
  overflow: scroll;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  }
  .rwd (sm;{
    background-color: white;
  });;
}

.note {
  ol {
    list-style: auto;
  }
  padding: 10px 20px;
  background-color: #f2f2f2;
}

h3.ant-typography {
  &.header {
    text-align: center;
    color: #3d3d3d;
    margin-bottom: 15px;
  }
}

.info-card {
  padding: 30px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  width: 500px;
  .rwd (sm;{
    box-shadow: none;
    padding: 20px;
    width: 100%;
  });;
}

.warning-notice {
  padding: 10px;
}

.space-style {
  width: 100%;
  justify-content: space-between;
}

.list {
  &--item {
    display: flex;
    position: relative;
    margin-bottom: 10px;
    &--label {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      background-color: #f6f6f6;
      padding: 10px;
      width: 80px;
      text-align: center;
      margin-right: 1px;
      flex-shrink: 0;
    }
    &--value {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      background-color: #f6f6f6;
      padding: 10px;
      width: 100%;
      color: #7f7f7f;
    }
    &--extra {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #02a7f0;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      &:hover {
        color: darken(#02a7f0, 5%);
      }
    }
  }
}
