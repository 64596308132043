// rwd斷點
.rwd (@point;@content) {
  & when (@point = xs) {
    @media (max-width: 375px) {
      @content();
    }
  }
  & when (@point = sm) {
    @media (max-width: 568px) {
      @content();
    }
  }
  & when (@point = pad) {
    @media (max-width: 768px) {
      @content();
    }
  }
  & when (@point = md) {
    @media (max-width: 1024px) {
      @content();
    }
  }
  & when (@point = lg) {
    @media (max-width: 1200px) {
      @content();
    }
  }
  & when (@point = llg) {
    @media (max-width: 1540px) {
      @content();
    }
  }
}
